import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FormRegisterCompany from "../components/forms/formRegisterCompany"
import FormRegisterCustomer from "../components/forms/formRegisterCustomer"
import FormRegisterDealer from "../components/forms/formRegisterDealer"

const TransportCompany = () => {
  const [modal, setModal] = useState(false)

  const [modalRegisterCustomer, setModalRegisterCustomer] = useState(false)
  const [
    modalRegisterTransportCompany,
    setModalRegisterTransportCompany,
  ] = useState(false)
  const [modalRegisterCarDealer, setModalRegisterCarDealer] = useState(false)

  const resetModal = () => {
    setModal(false)
    setModalRegisterCustomer(false)
    setModalRegisterTransportCompany(false)
    setModalRegisterCarDealer(false)
  }

  return (
    <Layout>
      {/* eslint-disable jsx-a11y/control-has-associated-label */}
      <SEO title="Transport Company" />

      <section className="subpage-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2>
                Car transport has never been <span>easier</span>
              </h2>
              <button
                onClick={e => setModal(true)}
                className="btn-custom btn-custom--green"
              >
                Register now
              </button>
            </div>
            <div className="col-lg-6 text-right">
              <img
                className="img-fluid img-main"
                src={require("../assets/images/global-phone.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="subpage-icons">
        <div className="container-fluid">
          <h2>
            Grow your <br />
            business with us and receive
          </h2>
          <div className="row justify-content-center">
            <div className="col-md-3">
              <div className="subpage-icons__ico">
                <img
                  src={require("../assets/images/transport-company-ico-1.svg")}
                  alt=""
                />
              </div>
              <h4>
                More <br /> customers
              </h4>
              <p>
                Expand your audience with our wide customer database. We will
                hep you reach more customers and make your company recognisable.
              </p>
            </div>
            <div className="col-md-3">
              <div className="subpage-icons__ico">
                <img
                  src={require("../assets/images/transport-company-ico-2.svg")}
                  alt=""
                />
              </div>
              <h4>
                Easy tools <br />
                to manage your profile
              </h4>
              <p>
                Invoicing system, buil-it chat app and many more to simplify
                your life and have every essential thing under one roof.
              </p>
            </div>
            <div className="col-md-3">
              <div className="subpage-icons__ico">
                <img
                  src={require("../assets/images/transport-company-ico-3.svg")}
                  alt=""
                />
              </div>
              <h4>
                Personalized <br /> Website
              </h4>
              <p>
                Be in control of how you want your profile to look! We provide
                the tools and you fully customise your own website. Stand out
                from the crowd!
              </p>
            </div>
            <div className="col-md-3">
              <div className="subpage-icons__ico">
                <img
                  src={require("../assets/images/transport-company-ico-4.svg")}
                  alt=""
                />
              </div>
              <h4>
                Verified <br />
                reviews
              </h4>
              <p>
                Only checked reviews from genuine clients will be approved on
                the portal. You can trust other people's feedback.
              </p>
            </div>
            <div className="col-md-3">
              <div className="subpage-icons__ico">
                <img
                  src={require("../assets/images/transport-company-ico-5.svg")}
                  alt=""
                />
              </div>
              <h4>
                Invoicing <br />
                system
              </h4>
              <p>
                Create your invoices in only few clicks. Most of the job will be
                done by us. Save your time avoiding complicated paper work!
              </p>
            </div>
            <div className="col-md-3">
              <div className="subpage-icons__ico">
                <img
                  src={require("../assets/images/transport-company-ico-6.svg")}
                  alt=""
                />
              </div>
              <h4>
                Rewards for <br />
                every job completed
              </h4>
              <p>
                Collect bonus points and once you will reach the treshold
                collect your rewards.
              </p>
            </div>
            <div className="col-md-3">
              <div className="subpage-icons__ico">
                <img
                  src={require("../assets/images/transport-company-ico-7.svg")}
                  alt=""
                />
              </div>
              <h4>
                Chat app <br />
                with customers
              </h4>
              <p>
                Easy way to communicate with your clients. Simple and free
                built-in app avaiable in your own personal website to simplify
                communication with customers.
              </p>
            </div>
            <div className="col-md-3">
              <div className="subpage-icons__ico">
                <img
                  src={require("../assets/images/transport-company-ico-8.svg")}
                  alt=""
                />
              </div>
              <h4>Marketing support fromour experienced team</h4>
              <p>
                Get the best advise on how to advertise and promote your
                buisness. We will also help you build your website to maximise
                the potential of engaging more potential clients.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="subpage-join">
        <div className="container">
          <h2>Join today</h2>
          <div className="row align-items-center flex-column-reverse flex-md-row">
            <div className="col-xl-4 col-md-6">
              <div className="pricing-tile">
                <div className="pricing-tile-header">Basic package</div>
                <div className="pricing-tile-price">
                  <sup>£</sup>99<small>+VAT</small>
                </div>
                <div className="pricing-tile-label">per month</div>
                <button
                  onClick={e => setModal(true)}
                  className="btn-custom btn-custom--block btn-custom--green"
                >
                  Register
                </button>
                <div className="pricing-tile-info">
                  After offer ends price will be <strong>£149 + VAT</strong>
                </div>
              </div>
            </div>
            <div className="col-xl-5 offset-xl-2 col-md-6">
              <ul className="custom-list custom-list--arrow">
                <li>This Package will be available soon</li>
                <li>Register your interest now by providing e-mail address</li>
                <li>
                  <strong>First 200</strong> companies in UK will be awarded
                  with unique profile on Drivelee
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {modal && (
        <>
          <div className="custom-modal">
            {modalRegisterCustomer === true ? (
              <FormRegisterCustomer />
            ) : modalRegisterTransportCompany === true ? (
              <FormRegisterCompany />
            ) : modalRegisterCarDealer === true ? (
              <FormRegisterDealer />
            ) : (
              <>
                <h3>Continue as</h3>
                <div className="row justify-content-center">
                  <div className="col-sm-4">
                    <button
                      onClick={e => setModalRegisterCustomer(true)}
                      className="tile"
                    >
                      <div className="tile__thumb">
                        <img
                          src={require("../assets/images/ico-customer.svg")}
                          alt=""
                        />
                      </div>
                      <p>Customer</p>
                    </button>
                  </div>
                  <div className="col-sm-4">
                    <button
                      onClick={e => setModalRegisterTransportCompany(true)}
                      className="tile"
                    >
                      <div className="tile__thumb">
                        <img
                          src={require("../assets/images/ico-transport-company.svg")}
                          alt=""
                        />
                      </div>
                      <p>
                        Transport <br /> Company
                      </p>
                    </button>
                  </div>
                  <div className="col-sm-4">
                    <button
                      onClick={e => setModalRegisterCarDealer(true)}
                      className="tile"
                    >
                      <div className="tile__thumb">
                        <img
                          src={require("../assets/images/ico-car-dealer.svg")}
                          alt=""
                        />
                      </div>
                      <p>
                        Car <br /> Dealer
                      </p>
                    </button>
                  </div>
                </div>
              </>
            )}
            <button
              className="custom-modal__close"
              onClick={resetModal}
            ></button>
          </div>
          <button
            className="custom-modal__overlay"
            onClick={resetModal}
          ></button>
        </>
      )}
    </Layout>
  )
}

export default TransportCompany
